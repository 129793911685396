import React, { useState } from "react";
import "./Hubspot.css";
import MegaHeader from "../mega-header";
import "bootstrap/dist/css/bootstrap.min.css";
import arrowh from "../../res/images/arrowh.png";
import handh from "../../res/images/handh.png";
import funnelh from "../../res/images/funnelh.png";
import webh from "../../res/images/webh.png";
import doth from "../../res/images/doth.png";
import numberingh from "../../res/images/numberingh.png";
import { Modal, Button } from "react-bootstrap";
import worriedman from "../../res/images/worriedman.png";
import frame2 from '../../res/images/Frame 2.png';
import email from "../../res/images/envelope-open.png";
import sendemail from "../../res/images/send.png";
import notification from "../../res/images/bell-on.png";
import arrowpointer from "../../res/images/arrow-pointer.png"

const Hubspot = () => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  return (
    <>
      <MegaHeader />

      <div className="row px mt-7 ">
        <div className="col-6 d-flex align-items-center px-5">
         <div>
         <h1 className="email-marketing">Email Marketing<br/>Magic: Turning<br/>Data into Dollars</h1>
          
          <p className="email-p">
            Without detailed email analytics, marketers struggle to understand
            campaign effectiveness, optimize content, and improve key metrics
            like open rates and click-throughs.
          </p>
         </div>
        </div>
        <div className="col-6 px-5">
          <img className="w-100 " alt="man" src={worriedman}></img>
        </div>
      </div>

      <div className="row" style={{marginTop:"10vw"}}>
        <div className="col-6 d-flex align-items-center">
          <img
            onClick={handleShow}
            src={frame2}
            // height={350}
            // width={350}
            alt="lp"
            style={{ cursor: "pointer" }}
          ></img>
        </div>
        <div className="col-lg-6 col-md-6">
          <p className="power-email">
          We developed a powerful email <br/> analytics dashboard that provides:
          </p>
          <div className="d-flex">
            <img src={arrowh} alt="80"></img>

            <p style={{ marginLeft: "1vw" }}>
            At-a-glance metrics for sent, delivered, opened, clicked, and bounced emails 
            </p>
          </div>

          <div className="d-flex">
            <img src={handh} alt="81"></img>

            <p style={{ marginLeft: "1vw" }}>
            Detailed breakdowns of top-performing campaigns and content 
            </p>
          </div>

          <div className="d-flex">
            <img src={funnelh} alt="80"></img>

            <p style={{ marginLeft: "1vw" }}>
            Engagement funnels showing user behavior 
            </p>
          </div>

          <div className="d-flex mt-2">
            <img src={webh} alt="80"></img>

            <p style={{ marginLeft: "1vw" }}>
            Geographic and demographic insights on audience engagement 
            </p>
          </div>

          <div className="d-flex mt-2">
            <img src={doth} alt="80"></img>

            <p style={{ marginLeft: "1vw" }}>
            Campaign performance comparisons across different types and subjects 
            </p>
          </div>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={handleClose}
        className="custom-modal"
        centered
      >
        <Modal.Header closeButton className="modal-header">
          <Modal.Title>GA 4</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <iframe
        title="d"
        style={{width:"100%", height:"700px" }}
        frameBorder="0"
        allowFullScreen="true"
        src="https://lookerstudio.google.com/embed/reporting/7ff6140c-23ef-4463-a1a1-dafcf3e818ec/page/p_pjz486avjd"
      />
        </Modal.Body>
      </Modal>

      {/* Steps involved */}
      <div className="row mt-7 px " style={{ marginBottom: "7vw" }}>

        <div>
          <p className="dashboard-empowered">The dashboard empowered marketers with actionable insights</p>
        </div>

      <div className="col-3">
  <div className="card-style px-3 py-3">
    <img src={email}  alt="email icon" className="card-icon mb-1" />
    <p className="card-title-hubspot">Email Open Rate</p>
    <p className="card-percentage"><strong>26%</strong></p>
    <p className="card-description">Increased email open rates by 26% through optimized subject lines and send times</p>
  </div>
</div>

<div className="col-3">
  <div className="card-style px-3 py-3">
    <img src={arrowpointer} style={{height:"auto" , width:"auto"}}  alt="email icon" className="card-icon mb-1" />
    <p className="card-title-hubspot">Click through Rate</p>
    <p className="card-percentage"><strong>66.7%</strong></p>
    <p className="card-description">Boosted click-through rates by 66.7% by identifying most engaging content </p>
  </div>
</div>

<div className="col-3">
  <div className="card-style px-3 py-3">
    <img src={notification}  alt="email icon" className="card-icon mb-1" />
    <p className="card-title-hubspot">Unsubscribe Rates</p>
    <p className="card-percentage"><strong>27%</strong></p>
    <p className="card-description">Reduced unsubscribe rates by 27% via better audience segmentation </p>
  </div>
</div>

<div className="col-3">
  <div className="card-style px-3 py-3">
    <img src={sendemail}  alt="email icon" className="card-icon mb-1" />
    <p className="card-title-hubspot">Sent Emails</p>
    <p className="card-percentage"><strong>95.9%</strong></p>
    <p className="card-description">Improved overall deliverability to 95.9% of sent emails </p>
  </div>
</div>

      </div>
    </>
  );
};

export default Hubspot;
